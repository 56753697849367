import { useEffect, useMemo, useRef, useState } from "react";

import "./useTypingText.css";

export type UseTypingTextProps = {
  text: string;
  msPerChar?: number;
  onDone?: () => void;
};

export function useTypingText(props: UseTypingTextProps) {
  const { text, msPerChar = 30, onDone } = props;
  const defaultElements = useMemo(() => {
    return text.split("").map((char, i) => (
      <span key={i} className="typing-char untyped">
        {char}
      </span>
    ));
  }, [text]);
  const [displayElements, setDisplayElements] = useState<
    React.ReactElement[] | null
  >(null);
  const [done, setDone] = useState(false);
  const iState = useRef({
    text,
    currentChar: 0,
    typeOutInterval: null as ReturnType<typeof setTimeout> | null,
    onDone,
  });
  iState.current.text = text;
  useEffect(() => {
    const iStateCurrent = iState.current;
    const keepTyping = () => {
      if (iStateCurrent.currentChar >= iStateCurrent.text.length) {
        iStateCurrent.onDone?.();
        setDone(true);
        if (iStateCurrent.typeOutInterval !== null) {
          clearInterval(iStateCurrent.typeOutInterval);
          iStateCurrent.typeOutInterval = null;
        }
        return;
      }
      iStateCurrent.currentChar++;
      const splitParts = iStateCurrent.text.split("");
      setDisplayElements([
        ...splitParts.slice(0, iStateCurrent.currentChar).map((char, i) => (
          <span key={i} className="typing-char typed">
            {char}
          </span>
        )),
        ...splitParts.slice(iStateCurrent.currentChar).map((char, i) => (
          <span
            key={i + iStateCurrent.currentChar}
            className="typing-char untyped"
          >
            {char}
          </span>
        ))
      ]);
    };
    iStateCurrent.typeOutInterval = setInterval(keepTyping, msPerChar);
    return () => {
      if (iStateCurrent.typeOutInterval !== null) {
        clearInterval(iStateCurrent.typeOutInterval);
        iStateCurrent.typeOutInterval = null;
      }
    };
  }, [msPerChar]);
  return [displayElements ?? defaultElements, done];
}
