import React from "react";
import { useTypingText } from "./useTypingText";
import "./App.css";

const detailsText = ` is a small video game studio focused on building out
Open Sourcerer, an educational platformer in which the player uses JavaScript
to create their spells.`.replace("\n", " ");

function App() {
  const mainText = useTypingText({
    text: detailsText
  });

  return (
    <div className="app">
      <header className="app-header">
        <div className="gh-logo">
        </div>
        <h1>
          <span>Gnarled Helix</span>
          <span className="llc-text">LLC</span>
        </h1>
      </header>
      <div className="about">
        <p>
          <b>Gnarled Helix LLC</b>{mainText}
        </p>
      </div>
    </div>
  );
}

export default App;
